<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            Formulaire <span v-if="contact == null">d'ajout </span>
            <span v-else>de modification </span>d'un contact
          </h1>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="card">
        <div class="collapse show">
          <form
            class="form"
            @submit.prevent="
              contact == null ? createContact() : modifyContact()
            "
          >
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Nom & prénom</label
                >
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="formContact.last_name"
                        type="text"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Nom"
                      />
                    </div>
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="formContact.first_name"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Prénom"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="contact == null" class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Promoteur</label
                >
                <div class="d-flex col-lg-10 fv-row">
                  <PromotersSelect @selected="selectContactPromoterOption" />
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addPromoterModal"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Agence</label
                >
                <div class="d-flex col-lg-10 fv-row">
                  <AgenciesSelect @selected="selectAgencyOption" />
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addContactModal"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Poste</label
                >
                <div class="col-lg-10 fv-row">
                  <select
                    v-model="formContact.position"
                    class="form-select form-select-solid form-select-lg me-4"
                  >
                    <option value="Assistant de Direction">
                      Assistant de Direction
                    </option>
                    <option value="Assistant de programme">
                      Assistant de programme
                    </option>
                    <option value="Développeur">Développeur</option>
                    <option value="Directeur">Directeur</option>
                    <option value="Directeur Associé">Directeur Associé</option>
                    <option value="Directeur commercial">
                      Directeur commercial
                    </option>
                    <option value="Directeur d'Agence">
                      Directeur d'Agence
                    </option>
                    <option value="Directeur de développement">
                      Directeur de développement
                    </option>
                    <option value="Directeur de programme">
                      Directeur de programme
                    </option>
                    <option value="Directeur des Partenariats">
                      Directeur des Partenariats
                    </option>
                    <option value="Directeur des ventes bloc">
                      Directeur des ventes bloc
                    </option>
                    <option value="Directeur Général">Directeur Général</option>
                    <option value="Directeur Général Adjoint">
                      Directeur Général Adjoint
                    </option>
                    <option value="Gérant">Gérant</option>
                    <option value="Directeur Régional">
                      Directeur Régional
                    </option>
                    <option value=" Directeur technique">
                      Directeur technique
                    </option>
                    <option value="Directeur Opérationnel">
                      Directeur Opérationnel
                    </option>
                    <option value="Fondateur">Fondateur</option>
                    <option value="Juriste">Juriste</option>
                    <option value="Président">Président</option>
                    <option value="Responsable administration des ventes">
                      Responsable administration des ventes
                    </option>
                    <option value="Responsable">Responsable</option>
                    <option value="Responsable de développement">
                      Responsable de développement
                    </option>
                    <option value="Responsable de programme">
                      Responsable de programme
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Téléphone</label
                >
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="formContact.phone_number"
                        v-mask="'00 00 00 00 00'"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Premier téléphone"
                      />
                    </div>
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="formContact.second_phone_number"
                        v-mask="'00 00 00 00 00'"
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Second téléphone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6">Mail</label>
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="formContact.email"
                    type="email"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Mail"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Date limite de rappel</label
                >
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="formContact.max_callback_date"
                    type="date"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Date limite de rappel"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <router-link
                to="/contacts"
                class="btn btn-light btn-active-light-primary me-2"
              >
                Retour
              </router-link>
              <button type="submit" class="btn btn-primary">
                <span v-if="contact == null">Ajouter </span>
                <span v-else>Modifier</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    id="addPromoterModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addPromoterModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un promoteur</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <form class="form">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Nom du promoteur</label
              >
              <div class="col-lg-10 fv-row">
                <input
                  v-model="formPromoter.name"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Nom du promoteur"
                />
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Nom du dirigeant</label
              >
              <div class="col-lg-10 fv-row">
                <input
                  v-model="formPromoter.director_name"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Nom du dirigeant"
                />
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Adresse siège</label
              >
              <div class="col-lg-10">
                <AddressInput
                  :can-create-address="canCreatePromoterAddress"
                  @addressCreated="catchPromoterAddressId"
                ></AddressInput>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click.prevent="createAddressThenPromoter()"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    id="addContactModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addContactModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter une agence</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <form class="form">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Nom de l'agence</label
              >
              <div class="col-lg-10 fv-row">
                <input
                  v-model="formAgency.name"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Nom de l'agence"
                />
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Téléphone de l'agence</label
              >
              <div class="col-lg-10 fv-row">
                <input
                  v-model="formAgency.phone_number"
                  v-mask="'00 00 00 00 00'"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Téléphone l'agence"
                />
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Adresse de l'agence</label
              >
              <div class="col-lg-10">
                <AddressInput
                  :can-create-address="canCreateAgencyAddress"
                  @addressCreated="catchAgencyAddressId"
                ></AddressInput>
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-2 col-form-label required fw-bold fs-6"
                >Promoteur</label
              >
              <div class="col-lg-10 fv-row">
                <PromotersSelect @selected="selectAgencyPromoterOption" />
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click.prevent="createAddressThenAgency()"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import AgenciesSelect from "@/components/selects/AgenciesSelect";
import PromotersSelect from "@/components/selects/PromotersSelect";
import AddressInput from "@/components/selects/AddressInput";
import store from "@/store";

export default {
  name: "ContactsForm",
  components: {
    AgenciesSelect,
    PromotersSelect,
    AddressInput,
  },
  data: function () {
    return {
      formContact: {
        id: null,
        first_name: "",
        last_name: "",
        phone_number: "",
        second_phone_number: "",
        email: "",
        position: "",
        promoter_id: "",
        agency_id: "",
        max_callback_date: "",
      },
      formPromoter: {
        name: "",
        director_name: "",
        head_office_address: "",
      },
      formAgency: {
        name: "",
        phone_number: "",
        address: "",
        promoter: "",
      },
      arrayPromoters: [],
      canCreatePromoterAddress: false,
      canCreateAgencyAddress: false,
    };
  },
  computed: {
    ...mapGetters(["promoters"]),
    ...mapGetters(["agencies"]),
    ...mapGetters(["contact"]),
  },
  mounted() {
    const route = useRoute();
    if (route.params.id) {
      if (route.params.id != "add") {
        this.showContact(route.params.id);
      } else {
        store.commit("contact", null);
      }
    }
  },
  methods: {
    ...mapActions(["postContactWithAction"]),
    ...mapActions(["updateContact"]),
    ...mapActions(["getContact"]),
    ...mapActions(["postPromoter"]),
    ...mapActions(["postAgency"]),

    // Contact
    createContact() {
      this.postContactWithAction(this.formContact);
    },
    modifyContact() {
      this.updateContact(this.formContact);
    },
    async showContact(id) {
      await this.getContact(id);
      this.formContact.id = this.contact.id;
      this.formContact.first_name = this.contact.first_name;
      this.formContact.last_name = this.contact.last_name;
      this.formContact.phone_number = this.contact.phone_number;
      this.formContact.second_phone_number = this.contact.second_phone_number;
      this.formContact.email = this.contact.email;
      this.formContact.position = this.contact.position;
      this.formContact.promoter = this.contact.promoter;
    },

    // Create promoter
    async createAddressThenPromoter() {
      await this.createPromoterAdrress();
      this.canCreatePromoterAddress = false;
    },
    createPromoterAdrress() {
      this.canCreatePromoterAddress = true;
    },
    catchPromoterAddressId(value) {
      this.formPromoter.head_office_address = value;
      this.canCreatePromoterAddress = false;
      this.postPromoter(this.formPromoter);
    },

    // Other promoter function
    selectContactPromoterOption(element) {
      this.formContact.promoter_id = element;
    },

    // Create agency
    async createAddressThenAgency() {
      await this.createAgencyAdress();
      this.canCreateAgencyAddress = false;
    },
    createAgencyAdress() {
      this.canCreateAgencyAddress = true;
    },
    catchAgencyAddressId(value) {
      this.formAgency.address = value;
      this.canCreateAgencyAddress = false;
      this.postAgency(this.formAgency);
    },

    // Other agency functions
    selectAgencyOption(element) {
      this.formContact.agency_id = element;
    },
    selectAgencyPromoterOption(element) {
      this.formAgency.promoter = element;
    },
  },
};
</script>
